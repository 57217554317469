import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTableOfContentsStore = (id = 1) => defineStore(`table-of-contents-${id}`, () => {
    const links = ref([]);

    const resetLinks = () => {
        links.value = [];
    };

    const initializeLinks = content => {
        links.value = content;
    };

    return {
        links,
        resetLinks,
        initializeLinks,
    };
})();
